import React from "react";
import { Link } from "react-router-dom";
import { useMedia } from "../lib/hooks";
import LinxLogo from "../assets/linx-logo.svg";
import LinkedIn from "../assets/linkedin.svg";
import { LinkedInURL } from "../Constants";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <SiteMap />
      <FooterBanner />
    </div>
  );
}

function SiteMap() {
  const wrappedContent = useMedia(["(min-width: 830px)"], [false], true);

  let mainClass = "site-map";
  let colLogoClass = "footer-col-logo";
  let colLogoSocialClass = "footer-social";
  let colSmallClass = "footer-col-small";
  let ourOfficeClass = "footer-col-small our-office";

  if (wrappedContent) {
    mainClass = "site-map-mobile";
    colLogoClass = "footer-col-logo-mobile";
    colSmallClass += " footer-col-small-mobile";
    colLogoSocialClass += " footer-social-mobile";
    ourOfficeClass += " our-office-mobile"
  }

  const renderLinxInfo = () => (
    <div className={colLogoClass}>
      <img src={LinxLogo} alt="Linx Systems Logo" />
      <p />
      <a href={LinkedInURL} target="_blank" rel="noreferrer">
        <img className={colLogoSocialClass} src={LinkedIn} alt="LinkedIn" />
      </a>
    </div>
  );

  const renderLinksCol = () => (
    <div className={colSmallClass}>
      <span>Links</span>
      <Link to="/">Home</Link>
      <Link to="/about">About Us</Link>
      <Link to="/careers">Careers</Link>
      <Link to="/contact">Contact Us</Link>
    </div>
  );

  // const renderAboutCol = () => (
  //   <div className={colSmallClass}>
  //     <span>About</span>
  //     <Link to="/about">Our Story</Link>
  //     <Link to="/careers">Careers</Link>
  //   </div>
  // );

  const renderOurOfficeCol = () => (
    <div className={ourOfficeClass}>
      <span>Our Office</span>
      <span className="location">
        1702 Tullamore Ave. Suite C<br />
        Bloomington, IL 61704
      </span>
      <span className="location">
        Phone: <a href="tel:3096642011" className="link">(309) 664-2011</a>
        <br />
        Fax: (309) 664-2012
      </span>
      <span className="location">Email: <a href="mailto:linx@linxsystems.com" className="link">linx@linxsystems.com</a></span>
    </div>
  );

  const renderDivider = () => <div className="footer-hr" />;

  return (
    <div className={mainClass}>
      {renderOurOfficeCol()}
      {wrappedContent && renderDivider()}
      {!wrappedContent && renderLinksCol()}
      {/*{!wrappedContent && renderAboutCol()}*/}
      {wrappedContent && (
        <div className="footer-2-col">
          {renderLinksCol()}
          {/*{renderAboutCol()}*/}
        </div>
      )}
      {wrappedContent && renderDivider()}
      {renderLinxInfo()}
    </div>
  );
}

function FooterBanner() {
  const smallFont = useMedia(["(max-width: 350px)"], [true], false);
  return (
    <div className={`banner${smallFont ? " banner-small-font" : ""}`}>
      <span>© 2024 LINX SYSTEMS Inc. All rights reserved.</span>
    </div>
  );
}
